import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import BlockContainer from "../components/block-container"

const NotFoundPage = () => (
  <>
    <SEO title="Thank You" />
    <Layout headerLabel="Really good marketing & design">
      <BlockContainer>
        <h1>Thank you</h1>
        <p>Someone will reach out to you soon.</p>
      </BlockContainer>
    </Layout>
  </>
)

export default NotFoundPage
